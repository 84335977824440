import React from 'react';
import Post from '../components/post';
import LazyLoadImage from '../components/LazyLoadImage';


const DebtCollectionPage = () => {
    const article = {
        id: '7f2a8832-da4d-5509-a6a4-b802e9cad222',
        title: 'Debt Collectors Calling? Know Your Rights and How to Deal With Them',
        slug: '/debt-collection/',
        date: '2018-01-24T18:42:33.000Z',
        modified: '2021-11-04T18:50:06.000Z',
        excerpt: 'If you&#8217;re being inundated with calls from debt collectors, it&#8217;s important to understand your rights and how you can get them to stop harassing you. Learn more in this guide, and put an end to it once and for all.',
        featured_image: {
            source_url: '/media/debt-collection-0.png',
        },
        acf: {
            seo_title: '',
            robots: 'index, follow',
        },
        read_time_minutes: 6,
        content: (
            <>
                <p>Consumer debt is at an all-time high.</p>
                <p>
                    <a target="_blank" href="https://www.cnbc.com/2017/05/17/how-much-the-average-us-family-has-in-credit-card-debt.html" rel="noreferrer">Credit card debt totaled $978.9 billion in 2016</a>
                    {' '}
                    with an average personal debt of $8,377, a 7% increase from 2015.
                </p>
                <p>The average total household debt is a staggering $137,063, according to the Federal Reserve.</p>
                <p>
                    Collection agencies begin calling when you fall behind on payments, whether it is a
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=debt-collection&amp;sub2=credit-card" rel="noopener noreferrer" target="_blank">credit card</a>
                    {' '}
                    bill or a different type of debt.
                </p>
                <p>Talking to these agencies can be uncomfortable, embarrassing, and downright terrifying. </p>
                <p> But it doesn&rsquo;t have to be. </p>
                <p>
                    Here are six things to do when a debt collection agency contacts you, and five things you should keep in mind that they are
                    {' '}
                    <em>not </em>
                    {' '}
                    allowed to do while speaking with you.
                </p>
                <h2 id="1-dont-let-them-intimidate-you">1. Don&rsquo;t let them intimidate you.</h2>
                <p>The job of a debt collection agency is to get you to pay as much money as quickly as possible and some might even offer inaccurate information to get you to pay. </p>
                <p>&quot;Many debt collectors will immediately threaten litigation, seizure of property and even arrest, but 99.9 percent of the time those things are not going to happen, especially right away, but they want to create urgency from the consumer,&quot; says David Levin, an attorney with Luxenburg &amp; Levin, LLC specializing in fair debt collection. </p>
                <p>&quot;Don&rsquo;t let the debt collectors intimidate you into paying money that you do not owe because as a consumer you have rights under the Fair Debt Collection Practices Act (FDCPA).&quot;</p>
                <p>
                    <LazyLoadImage src="/media/number-of-debts-consumers-were-contacted-about-2017-1.png" alt="Number of Debts Consumers Were Contacted About (2017)" />
                </p>
                <h3>Collection agencies cannot threaten or lie to you. </h3>
                <p>Debt collection services are required to be honest and upfront about who they are and what they are doing – that they are a collections agency trying to get you to repay a debt. </p>
                <p>If a debt collector claims to be from a government agency, an attorney or law enforcement official – or otherwise claims to be someone they are not – they are breaking the law. </p>
                <p>
                    Also, be suspicious of debt collectors who ask for your Social Security number, bank account numbers or other personal financial details, as they might be
                    {' '}
                    <a target="_blank" href="http://www.consumer.ftc.gov/articles/0258-fake-debt-collectors" rel="noreferrer">fake debt collectors</a>
                    {' '}
                    trying to steal your identity.
                </p>
                <h2 id="2-learn-your-rights">2. Learn your rights. </h2>
                <p>When you are first contacted by a collection agency, either by phone or by mail, take some time to understand the collection process and your rights. </p>
                <p>The Fair Debt Collection Practices Act (FDCPA) prohibits collection agencies from threatening action or using abusive language. </p>
                <p>If you think that a debt collector has violated your rights, you can file a complaint with the Federal Trade Commission and the collection agency can be ordered to pay you up $1,000.</p>
                <p>&quot;If you don&rsquo;t want to be contacted by a debt collector anymore, you can request in writing that they cease all contact. If you don&rsquo;t have the ability to pay or need more time, this can be a way to get them off your back for the time being,&quot; Levin says.</p>
                <h3>Debt collectors cannot call you early in the morning or late at night. </h3>
                <p>
                    If you are receiving collections calls late at odd hours, the debt collection service is breaking the law. Collections agencies are
                    {' '}
                    <a target="_blank" href="https://www.privacyrights.org/debt-collection-know-your-rights" rel="noreferrer">only allowed to call between the hours of 8 a.m. and 9 p.m.</a>
                    {' '}
                    (unless you agree to talk with them at other times of day).
                </p>
                <h2 id="3-get-verification-of-the-debt">3. Get verification of the debt.</h2>
                <p>Under the Fair Debt Collection Practices Act (FDCPA), you have the right to request written verification of the alleged debt&nbsp;within 30 days of your receipt of the debt collector&rsquo;s notice advising you of your right to request verification. </p>
                <p>Before paying or providing additional&nbsp;information, Levin recommends sending a letter by certified mail&nbsp;(the request must be made in writing)&nbsp;requesting verification of the debt. </p>
                <p>&quot;Since the debt collectors are required to cease collection activities until they comply, this gives you time to investigate the debt or make arrangements for payment,&quot; says Levin.</p>
                <h3>Debt collectors cannot ignore your requests to verify the debt.</h3>
                <p>Sometimes collections agencies have bad information about the debts that consumers allegedly owe. </p>
                <p>Creditors can make mistakes and turn over outdated or inaccurate names or data about debt. </p>
                <p>If you receive a call from a collection agent asking you to pay a debt that you do not owe – or that you have already repaid – you have a right to ask the collection agency to verify the debt. </p>
                <p>
                    According to the
                    {' '}
                    <a target="_blank" href="http://www.acainternational.org/about/code-of-conduct" rel="noreferrer">Code of Ethics of ACA International</a>
                    , the Association of Credit and Collections Professionals, all debt collection services must accept written requests for verification of the debt.
                </p>
                <p>Simply put, if a collection agency says that you owe money, they must be able to prove it. </p>
                <p>
                    If a collections agency cannot verify the debt, they are required to stop trying to collect from you, and they are expected to report the unverified debt to credit reporting agencies in order to get it
                    {' '}
                    <a href="https://www.creditloan.com/credit-scores/improve-score-zero-mistakes/">removed from your credit report</a>
                    .
                </p>
                <h2 id="4-give-limited-information-over-the-phone">4. Give limited information over the phone.</h2>
                <p>
                    If you are contacted over the phone, be very careful about what information that you share, especially
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=debt-collection&amp;sub2=credit-card" rel="noopener noreferrer" target="_blank">credit card</a>
                    {' '}
                    information, social security numbers and bank account numbers.
                </p>
                <p>A very common form of identity theft is where someone poses a debt collector either for a valid debt or a fabricated one. </p>
                <p><LazyLoadImage src="/media/top-10-consumer-complaints-2.png" alt="Top 10 Consumer Complaints" /></p>
                <p>&quot;Be careful about making verbal admissions over the phone. These collection agencies record the calls and those recordings could come back to haunt you,&quot; Levin says.</p>
                <h3>Debt collectors cannot pester your family and friends.</h3>
                <p>
                    According to the FTC,
                    {' '}
                    <a target="_blank" href="http://www.consumer.ftc.gov/articles/0149-debt-collection" rel="noreferrer">debt collection services are allowed to contact other people who know you</a>
                    {' '}
                    – whether it&rsquo;s family or friends – but only to verify your address, your home phone number and your workplace.
                </p>
                <p>However, debt collectors are not allowed to discuss personal details about your debt with anyone but you, your spouse or your attorney. </p>
                <p>If a debt collector calls your neighbor or your brother or your boss, and tells them how much money you owe, that debt collector is violating the law.</p>
                <h2 id="5-work-out-a-payment-plan">5. Work out a payment plan.</h2>
                <p>If you determine that you are responsible for the debt, make every effort to pay back the money that you owe. If you are not in the position to write a single check for the amount, determine if the collection agency will work out a payment plan. </p>
                <p>&quot;Debt collectors deal with many people every single day, and not all of them want to pay off the debt as much as you do. Ask the debt collector to help you put together a plan to get the debt paid off. Be realistic as you put the plan together,&quot; says Jeanne Kelly, Credit Coach. </p>
                <p>&quot;It&rsquo;s better to stretch payments out over a longer period of time than to keep missing one payment after another.&quot;</p>
                <h3>Debt collectors cannot keep contacting you after you ask them to stop. </h3>
                <p>You can make collection agencies stop calling you by sending them a letter asking them to stop. </p>
                <p>
                    According to the
                    {' '}
                    <a target="_blank" href="http://business.ftc.gov/documents/fair-debt-collection-practices-act" rel="noreferrer">Fair Debt Collection Practices Act</a>
                    :
                </p>
                <p>&quot;If a consumer notifies a debt collector in writing that the consumer refuses to pay a debt or that the consumer wishes the debt collector to cease further communication with the consumer, the debt collector shall not communicate further with the consumer with respect to such debt, except – to advise the consumer that the debt collector&rsquo;s further efforts are being terminated; to notify the consumer that the debt collector or creditor may invoke specified remedies which are ordinarily invoked by such debt collector or creditor; or where applicable, to notify the consumer that the debt collector or creditor intends to invoke a specified remedy.&quot;</p>
                <p>Asking the debt collector to stop contacting you doesn&rsquo;t make your debt go away. </p>
                <p>Your creditor or the debt collector can still sue you for repayment. </p>
                <p>But you have the right to request to not be contacted any further, and the debt collectors have to respect your wishes.</p>
                <p><LazyLoadImage src="/media/type-of-loans-debt-collectors-called-about-in-2017-3.png" alt="Type of Loans Debt Collectors Called About in 2017" /></p>
                <h2 id="6-consider-hiring-a-consumer-attorney">6. Consider hiring a consumer attorney</h2>
                <p>If you are unable to work out the issue with the collection agency or feel that your rights have been violated, consider hiring a consumer attorney.</p>
                <p>Once the debt collectors are notified that you are represented by an attorney, all contact about the debt must go through the attorney instead of you. </p>
                <p>If a judge rules that the company has violated your rights, then they can be responsible for paying your attorney fees.</p>
                <h2 id="deal-with-debt-collectors-the-right-way">Deal with debt collectors the right way</h2>
                <p>Discussing your financial woes with someone whose primary concern is collecting your debt can be tiring, but knowing your rights and what agencies can and cannot do is the first step to maintaining control of the situation.</p>
                <p>Even if you request the agencies stop calling, you are still liable for the debt. </p>
                <p>
                    If you&rsquo;re fed up with the calls,
                    {' '}
                    <a href="/stop-debt-collector-calls/">learn more about how to get them to stop permanently</a>
                    .
                </p>
                <p>Sit down, work out a monthly budget, and consider the primary causes of your struggles.</p>
            </>
        ),
    };

    return (
        <Post article={article} />
    );
};

export default DebtCollectionPage;
